import { rgba } from 'polished';
import Colors from './colors';

const shadows = {
  superDark: '0px 2px 8px #000000',
  disabled: `0px 4px 8px ${rgba(Colors.deepSpace, 0.05)}, 0px 2px 4px rgba(0, 0, 0, 0.02)`,
  hovered: `0px 6px 14px ${rgba(Colors.deepSpace, 0.2)}, 0px 2px 6px rgba(0, 0, 0, 0.04)`,
  default: `0px 4px 12px ${rgba(Colors.deepSpace, 0.1)}, 0px 2px 4px rgba(0, 0, 0, 0.04)`,
};

export default shadows;
