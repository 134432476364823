import { Colors } from 'mineralsoft/components/common/constants';
import ButtonSizes from './sizes';

const {
  freshLinen,
  softFog,
  silver,
  charcoal,
  deepSpace,
  red,
  redDark,
  redPale,
  blue,
  blueDark,
  bluePale,
} = Colors;

const getHoveredColor = (props) => {
  const { primary, minimal, destructive, size, floating } = props;

  if (size === ButtonSizes.small) {
    return {
      bg: (floating ? freshLinen : silver),
      text: deepSpace,
    };
  } else if (destructive) {
    return {
      bg: (minimal ? redPale : redDark),
      text: (minimal ? redDark : freshLinen),
    };
  } else if (primary) {
    return {
      bg: (minimal ? bluePale : blueDark),
      text: (minimal ? blueDark : freshLinen),
    };
  } else if (minimal) {
    return {
      bg: (floating ? freshLinen : softFog),
      text: deepSpace,
    };
  }

  return { bg: freshLinen, text: deepSpace };
};

const getColors = (props, hovered) => {
  const { primary, minimal, destructive, size, floating } = props;

  if (hovered) {
    return getHoveredColor(props);
  }

  if (minimal) {
    return {
      bg: (floating ? softFog : freshLinen),
      text: deepSpace,
    };
  } else if (size === ButtonSizes.small) {
    return {
      bg: (floating ? freshLinen : softFog),
      text: charcoal,
    };
  } else if (primary) {
    return {
      bg: (destructive ? red : blue),
      text: freshLinen,
    };
  }

  return { bg: freshLinen, text: charcoal };
};

const getBorder = (props, hovered) => {
  const { floating, size, primary, minimal, destructive } = props;

  if (size === ButtonSizes.small || minimal) {
    return '1px solid transparent';
  }

  if (!primary && !(destructive && hovered) && !floating) {
    return `1px solid ${Colors.silver}`;
  }

  const color = getColors({ primary, destructive, floating }, hovered).bg;
  return `1px solid ${color}`;
};

export { getBorder, getColors };
