import React from 'react';
import { Provider } from 'react-redux';
import { createStore, compose } from 'redux';

function getlocalStorageState(key: string) {
  const raw = localStorage.getItem(key);
  return raw ? JSON.parse(raw) : {};
}

export function getlocalStorageKey(pageComponentName) {
  return `${window.jsdata.accountSlug}_${pageComponentName}_reduxStore`;
}

export default function withRedux(WrappedComponent, getReducer) {
  return class extends React.PureComponent {
    render() {
      const localStorageKey = getlocalStorageKey(
        WrappedComponent.displayName || WrappedComponent.name,
      );
      const localStorageState = getlocalStorageState(localStorageKey);
      const rootReducer = getReducer(localStorageState);

      // redux devtools wraps compose to optionally add itself if enabled
      // @ts-expect-error
      const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
      const store = createStore(rootReducer, composeEnhancers());

      store.subscribe(() => {
        // @ts-expect-error
        const state = JSON.stringify(store.getState().toJS());
        localStorage.setItem(localStorageKey, state);
      });

      return (
        <Provider store={store}>
          <WrappedComponent />
        </Provider>
      );
    }
  };
}
