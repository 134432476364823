import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

/**
 * Custom hook to provide URL query params sync handlers.
 *
 * @param defaultQueryParams
 */
export const useUrlQueryParamsSync = (defaultQueryParams?: URLSearchParams) => {
  const history = useHistory();
  const location = useLocation();

  /**
   * Set default query params on component mount.
   */
  useEffect(() => {
    if (defaultQueryParams) {
      const params = new URLSearchParams(defaultQueryParams);
      history.replace({ pathname: location.pathname, search: params.toString() });
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Updates Query Params in url with provided params.
   * @param queryParams
   */
  const updateUrlQueryParams = (queryParams: { [paramName: string]: string }) => {
    const params = new URLSearchParams(location.search);
    Object.keys(queryParams).forEach((paramName) => {
      params.set(paramName, queryParams[paramName]);
    });
    history.replace({ pathname: location.pathname, search: params.toString() });
  };

  /**
   * Replace Query Params in url with provided params.
   * @param queryParams
   */
  const setUrlQueryParams = (queryParams: { [paramName: string]: string }) => {
    const params = new URLSearchParams(queryParams);
    history.replace({ pathname: location.pathname, search: params.toString() });
  };

  /**
   * Get URL query param by name.
   * @param paramName
   *
   * @returns Returns `undefined` if param is not found. If param is found but empty returns empty string. Otherwise, returns value.
   */
  const getUrlQueryParam = (paramName: string): string | undefined => {
    const params = new URLSearchParams(location.search);

    return params.get(paramName) ?? undefined;
  };

  return { setUrlQueryParams, getUrlQueryParam, updateUrlQueryParams };
};

export default useUrlQueryParamsSync;
