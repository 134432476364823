/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';

export { useUrlQueryParamsSync } from './useUrlQueryParamsSync';
export { useFetch } from './useFetch';
export { useMutation } from './useMutation';

/**
 * <p>** Note: Currently only being used on ProcessingTable in the 2019 StatementIndex page,
 * this might be deprecated soon as RevenueIndex was added 2020.</p>
 *
 * <p>A hook for using data with localStorage as a persistence layer.</p>
 *
 * <p>Handles serialization using
 * [JSON.parse]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse} &
 * [JSON.stringify]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify}.</p>
 * <p>Reacts to external changes to the value using the
 * [storage event]{@link https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event},
 * which can even be used to communicate between different Mineralsoft tabs!</p>
 *
 * @param storageKey string
 * @returns [value: reactive value, update: function to update & persist the value]
 */
export function useLocalStorage(storageKey) {
  /** @todo check that we have access to localStorage */
  const initialValue = localStorage.getItem(storageKey);
  const [value, set] = useState(initialValue && JSON.parse(initialValue));

  const update = (newValue) => {
    set(newValue);
    localStorage.setItem(storageKey, JSON.stringify(newValue));
  };

  // React to changes to the key elsewhere
  useEffect(() => {
    const onStorageEvent = ({ key, newValue }) => {
      if (key !== storageKey || newValue === JSON.stringify(value)) return;
      update(JSON.parse(newValue));
    };

    window.addEventListener('storage', onStorageEvent);
    return () => window.removeEventListener('storage', onStorageEvent);
  }, [storageKey]);

  return [value, update];
}
