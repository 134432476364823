import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Signin } from './Signin';
import { Container, Logo, SubText, Background } from './styles';
import topographic from './topographic.svg';

export const AuthContainer = () => (
  <Router>
    <Container>
      <Logo src="/static/images/logo/png/logo-long-white-bg.png" />

      <Switch>
        <Route path="/signin" component={Signin} />
      </Switch>

      <SubText>
        Need help? <a href="mailto:mineralsoftsupport@enverus.com">Email Support</a>, or call{' '}
        <a href="tel:8664502445">(866) 450-2445</a>
      </SubText>

      <Background src={topographic} />
    </Container>
  </Router>
);
