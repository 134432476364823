import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Colors } from 'mineralsoft/components/common/constants';
import SubtleIndicator from './SubtleIndicator';

const SVG = styled.svg`
  display: inline-block;
  position: relative;
  height: 1em;
  width: 1em;
  margin: 0;
  padding: 0;
  transform-origin: 50% 50%;
  animation: anim-loading 0.75s ease-in-out infinite;

  @keyframes anim-loading {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const LoadingIndicator = ({
  contextColor,
  subtle,
  size,
  style,
}) => {
  const getColors = () => {
    const options = {
      [Colors.freshLinen]: [Colors.silver, Colors.blue],
      [Colors.softFog]: [Colors.silver, Colors.blue],
      [Colors.silver]: [Colors.timberwolf, Colors.deepSpace],
      [Colors.timberwolf]: [Colors.slate, Colors.freshLinen],
      [Colors.slate]: [Colors.charcoal, Colors.freshLinen],
      [Colors.charcoal]: [Colors.slate, Colors.freshLinen],
      [Colors.deepSpace]: [Colors.charcoal, Colors.gold],
      [Colors.red]: [Colors.redDark, Colors.freshLinen],
      [Colors.redDark]: [Colors.red, Colors.freshLinen],
      [Colors.blue]: [Colors.blueDark, Colors.freshLinen],
      [Colors.blueDark]: [Colors.blue, Colors.freshLinen],
      [Colors.gold]: [Colors.goldDark, Colors.goldPale],
    };

    return options[contextColor] || [Colors.blueDark, Colors.freshLinen];
  };

  const [backgroundColor, indicatorColor] = getColors();

  if (subtle) {
    return (<SubtleIndicator />);
  } else {
    return (
      <SVG
        style={{ ...style, width: size, height: size }}
        viewBox="0 0 16 16"
        fill="none"
        data-testid="loading"
      >
        <circle
          cx="8"
          cy="8"
          r="7"
          strokeWidth="2"
          stroke={backgroundColor}
        />
        <path
          strokeWidth="2"
          strokeLinecap="round"
          stroke={indicatorColor}
          d="M8 1C11.866 1 15 4.13401 15 8C15 9.933 14.2165 11.683 12.9497 12.9497"
        />
      </SVG>
    );
  }
};

LoadingIndicator.propTypes = {
  contextColor: PropTypes.string, // a MineralSoft color from the switches above
  subtle: PropTypes.bool,
  size: PropTypes.string,
  style: PropTypes.object,
};

LoadingIndicator.defaultProps = {
  contextColor: Colors.blue,
  subtle: false,
  size: '16px',
  style: {},
};

export default LoadingIndicator;
