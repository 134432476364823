/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { rgba } from 'polished';

import { Colors, Shadows } from 'mineralsoft/components/common/constants';

import BackgroundLayer from './backgroundLayer';
import { getColors, getBorder } from './helpers';
import ButtonSizes from './sizes';

const getIconMarginRight = ({ size, noChildren }) => {
  if (noChildren) {
    return '0px';
  } else if (size === ButtonSizes.small) {
    return '4px';
  }

  return '10px';
};

const getShadow = ({ floating, disabled }, hovered) => {
  if (!floating) {
    return 'none';
  }

  if (floating && disabled) {
    return Shadows.disabled;
  }

  if (hovered) {
    return Shadows.hovered;
  }

  return Shadows.default;
};

const backgroundLayerVisibility = ({ primary, destructive }) => {
  return (!primary && !destructive) ? 'block' : 'none';
};

const getBackgroundLayerColor = (size) => (
  rgba(Colors[(size === ButtonSizes.small ? 'silver' : 'softFog')], 0.5)
);

const getBackgroundLayerHoverOpacity = ({ floating, size }) => (
  (size === ButtonSizes.small && !floating) ? 0 : 1
);

export default styled.button`
  display: inline-block;
  box-shadow: ${(props) => getShadow(props, false)};
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  user-select: none;
  line-height: 15px;
  position: relative;
  backface-visibility: hidden;
  transform: translateZ(0);
  white-space: pre;

  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  height: ${({ size }) => (size === ButtonSizes.small ? '24px' : '36px')};
  width: ${({ wide }) => (wide ? '100%' : 'auto')};
  padding: 0;

  &, &:visited {
    background: ${(props) => getColors(props, false).bg};
    color: ${(props) => getColors(props, false).text};
    border: ${(props) => getBorder(props, false)};
  }

  &:hover, &:focus {
    text-decoration: none !important;
    background: ${(props) => getColors(props, true).bg} !important;
    color: ${(props) => getColors(props, true).text};
    border: ${(props) => getBorder(props, true)};
    outline: none;
    transform: translate3d(0, ${(props) => (props.disabled ? 0 : '-1px')}, 0);
    box-shadow: ${(props) => getShadow(props, true)};

    ${BackgroundLayer} {
      opacity: ${(props) => getBackgroundLayerHoverOpacity(props)};
    }
  }

  > div { /* button content */
    height: ${({ size }) => (size === ButtonSizes.small ? '24px' : '36px')};
    padding: 0 ${({ size }) => (size === ButtonSizes.small ? '6px' : '12px')};
    margin-top: -1px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    &:focus { outline: none; }
  }

  .icon {
    margin-right: ${getIconMarginRight};
    * { transition: all 0.15s ease-in-out; }
  }

  ${BackgroundLayer} {
    display: ${backgroundLayerVisibility};
    background: ${({ size }) => getBackgroundLayerColor(size)};
  }

  &::after {
    content: '';
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    position: absolute;
    box-sizing: border-box;
    left: -4px;
    top: -4px;
    opacity: 0;
    transition: 0.1s linear ease-in-out;
    border: 2px solid transparent;
    border-color: ${({ destructive: d }) => (d ? Colors.red : Colors.blue)};
    pointer-events: none;
    border-radius: 6px;
  }
  &:focus::after {
    opacity: 0.75;
  }

  div.loadingWrapper {
    /* 
      When there is no loading text, set position absolute
      so the button stays the same width during loading
    */
    ${({ hasLoadingText, componentLoading }) => !hasLoadingText && `
      position: absolute;
      top: 1px;
      left: 0;
      height: calc(100% - 2px);
      opacity: ${componentLoading ? 1 : 0};
    `}
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: ${(props) => getColors(props, false).bg};
    pointer-events: none;
  }
`;
