import React from 'react';
import ReactDOM from 'react-dom';
import AuthContainer from '../containers/Authorization';

$(() => {
  if ($('#signin').length) {
    initView();
  }
});

function initView() {
  ReactDOM.render(
    <AuthContainer />,
    document.getElementById('signin')
  );
}

export default AuthContainer;
