const spacing = {
  tn: '3px',
  sm: '5px',
  md: '10px',
  lg: '15px',
  xl: '20px',
  xxl: '25px',
};

export default spacing;
