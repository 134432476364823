const colors = {
  // greys
  freshLinen: '#FFFFFF',
  softFog: '#EAECF1',
  silver: '#D5D7E0',
  timberwolf: '#ACAFB9',
  slate: '#72757F',
  charcoal: '#474A54',
  deepSpace: '#181D23',
  brightGray: '#EFEFF1',
  gainsboro: '#DEDEE0',

  // gold
  goldPale: '#FAF4E6',
  gold: '#EAC97A',
  goldDark: '#806524',

  // blues 🎷
  bluePale: '#E7F3FF',
  blueLight: '#288BED',
  blueSelect: '#d9edf7',
  blue: '#0670DA',
  blueDark: '#005AB4',

  // reds
  redPale: '#ffecec',
  red: '#DF4C42',
  redDark: '#C22300',
  redDarker: '#812D28',
  danger: '#ff4d4f',

  // greens
  greenPale: '#CEEBCB',
  green: '#2D6125',
  greenDark: '#2D6125',

  // simple product colors
  oilGreen: '#14921F',
  gasRed: '#C30713',
  nglPurple: '#8D27FB',
  moneyFill: '#7BB579',

  // drilling info
  diGreen: '#6fbe44',

  warning: '#ffa500',

  white: '#ffffff',
};

export default colors;
