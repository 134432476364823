import styled, { keyframes } from 'styled-components';

import { Colors, FontSizes } from 'mineralsoft/components/common/constants';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: default;

  > * {
    z-index: 1;
    position: relative;
  }
`;

const halfFade = keyframes`
  from { opacity: 0; }
  to { opacity: 0.3; }
`;

export const Background = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transform: translateZ(0);
  animation-delay: 0.5s;
  animation: ${halfFade} 1.5s linear forwards;
  background-color: black;
  object-fit: cover;
`;

export const Logo = styled.img`
  margin-bottom: 16px;
  height: 48px;
  opacity: 0.9;
`;

export const SubText = styled.p`
  margin-top: 16px;
  color: ${Colors.timberwolf};
  font-size: ${FontSizes.S};
  opacity: 0.75;
  cursor: default;
  user-select: none;

  transition: all 0.5s ease-in-out;
  &:hover {
    transition: all 0.25s ease-in-out;
    opacity: 1;
  }

  a {
    cursor: pointer;
    color: ${Colors.timberwolf};
    transition: all 0.15s ease-in-out;
    &:hover {
      color: ${Colors.freshLinen};
    }
  }
`;

export const Card = styled('div').withConfig<{ loading: boolean }>({
  shouldForwardProp: (prop) => prop !== 'loading',
})`
  border-radius: 4px;
  background: ${Colors.freshLinen};
  color: ${Colors.deepSpace};
  width: 304px;
  padding: 24px;
  transition: all 0.2s ease-in-out;

  ${({ loading }) =>
    loading
      ? `
    > *:nth-last-child(n + 1) {
      opacity: 0.5;
      pointer-events: none;
    }
  `
      : ''}

  form > * {
    margin-top: 16px;
  }
`;
