// This a temporary solution for getting environment. It might be fixed later with config
export const getEnvironment = () => {
  const { hostname } = window.location;

  const env = hostname.split('.')[0];
  if (env === 'app') {
    return 'production';
  }
  if (env === 'staging') {
    return 'staging';
  }
  if (env === 'uat') {
    return 'uat';
  }
  if (env === 'dev') {
    return 'dev';
  }
  if (env === 'localhost') {
    return 'localhost';
  }

  return 'unknown';
};
