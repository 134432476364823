import type { CSSProperties } from 'react';
import React from 'react';
import styled from 'styled-components';

import IconContext from './IconContext';
import { Colors } from 'mineralsoft/components/common/constants';

const themedSVGAttributes = ['stroke', 'fill'];

const Parent = styled('div').withConfig<{
  size?: string;
  color?: string;
}>({
  shouldForwardProp: (prop) => prop !== 'color',
})`
  display: inline-block;
  ${({ size }) =>
    size &&
    `
    width: ${size};
    height: ${size};
  `}
  svg {
    max-width: 100%;
    max-height: 100%;
    // Set the colors for each themable attribute only if it already existed
    ${({ color }) =>
      themedSVGAttributes.map(
        (attr) => `
      & *[${attr}] {
        ${attr}: ${Colors[color] || color || 'currentColor'};
      }
    `,
      )}
    }
  }
`;

export const IconNames = IconContext.keys().map((filepath) => {
  let formattedFilePath = filepath;
  const isIconFullPath = formattedFilePath.startsWith('/');
  if (isIconFullPath) {
    formattedFilePath = formattedFilePath.slice(formattedFilePath.lastIndexOf('/') + 1);
  }

  return formattedFilePath.replace(/(\.\/|\.svg)/g, '');
});

interface IconProps {
  /**
   * Prefers MS color name, but falls back to CSS color values
   */
  name?: string;
  /**
   * Name of the icon file, without '.svg'. Icon files are in ./svgs/
   */
  color?: string;
  /**
   * CSS size property, applied to width and height
   */
  size?: string;
  style?: CSSProperties | undefined;
  title?: string;
}

export default function Icon({ name = 'plus', size = '16px', ...props }: IconProps) {
  if (name && !IconNames.includes(name)) {
    return null;
  }
  const svg = IconContext(`./${name}.svg`);

  return (
    <Parent className="icon" size={size} {...props} dangerouslySetInnerHTML={{ __html: svg }} />
  );
}
