const getUrlParam = (param) => {
  const pageURL = window.location.search.substring(1);
  const params = pageURL.split('&');
  let val = false;
  params.forEach((pair) => {
    const [name, value] = pair.split('=');
    if (name === param) {
      val = value;
    }
  });
  return val;
};

export default getUrlParam;
