import debounce from 'mineralsoft/utils/debounce';

$(() => {
  if ($('#password-reset').length) {
    /*
    When the password changes (keyup), submit it to the validate password api.
    Debounce is used to introduce a slight delay.  Debounce > throttle as debounce
    takes the very last input in the interval.
    */

    const debounceValidatePassword = debounce(window.validate_password, 350);
    $('#id_new_password1').keyup(() => debounceValidatePassword($('#id_new_password1').val(), 'status_messages'));

    if ($('#id_new_password1').val()) {
      debounceValidatePassword($('#id_new_password1').val(), 'status_messages');
    }

    $('.textinput').focus(() => {
      $(this).attr('type', 'text');
    });
    $('.textinput').blur(() => {
      $(this).attr('type', 'password');
    });
  }
});

export default null;
