import type { AxiosError } from 'axios';
import axios from 'axios';
import { useEffect, useState } from 'react';

type HttpResponse = AxiosError;

interface UseFetchResult<T> {
  data?: T;
  error?: AxiosError;
  loading: boolean;
  refetch: () => void;
}

function useFetch<T>(
  url: string,
  path = [] as string[],
  fetchOnFirstLoad = true,
): UseFetchResult<T> {
  const [data, setData] = useState<T>();
  const [previousUrl, setPreviousUrl] = useState<string>(fetchOnFirstLoad ? '' : url);
  const [error, setError] = useState<HttpResponse>();
  const [loading, setLoading] = useState<boolean>(fetchOnFirstLoad && !!url);
  const [shouldFetch, setShouldFetch] = useState(fetchOnFirstLoad);

  const refetch = () => {
    if (!shouldFetch) {
      setError(undefined);
      setShouldFetch(true);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    let wasCanceled = false;
    (async function fetch() {
      if (url && (shouldFetch || url !== previousUrl)) {
        try {
          setPreviousUrl(url);
          setLoading(true);
          let { data: responseData } = await axios.get(url, { signal: abortController.signal });

          path.forEach((segment) => {
            responseData = responseData[segment];
          });

          setShouldFetch(false);
          setData(responseData);
        } catch (err) {
          if (axios.isCancel(err)) {
            wasCanceled = true;

            return;
          }

          console.error(err);
          setError(err);
        } finally {
          if (!wasCanceled) {
            setShouldFetch(false);
            setLoading(false);
          }
        }
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [url, shouldFetch]);

  return { data, error, loading, refetch };
}

export default useFetch;
export { useFetch };
