const fontSizes = {
  XXL: '32px',
  XL: '24px',
  L: '20px',
  M: '15px',
  S: '13px',
  XS: '12px',
};

export default fontSizes;
