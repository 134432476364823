import React, { useState } from 'react';

import { Card } from '../styles';
import Button from 'mineralsoft/components/Button';

export const Signin = () => {
  const [loading, setLoading] = useState(false);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    window.location.assign(`/auth0/login${window.location.search}`);
  };

  return (
    <Card loading={loading}>
      <form noValidate method="post" onSubmit={onSubmit}>
        <Button
          wide
          primary
          loading={loading}
          loadingText="Logging in..."
          type="submit"
          data-testid="loginSubmitButton"
        >
          Sign in
        </Button>
      </form>
    </Card>
  );
};
