/* eslint-disable no-plusplus */
import React from 'react';
import ReactDOM from 'react-dom';

const camelCase = str => str.replace(/-([a-z])/g, groups => groups[1].toUpperCase());

const renderToEl = (
  el,
  Component,
  filterProps = () => true,
  chooseComponent = () => Component,
) => {
  if (!el) { return; }

  const componentProps = {};
  const elementAttributes = {};
  if (el.attributes.length) {
    for (let i = 0; i < el.attributes.length; i++) {
      const currentAttr = el.attributes[i];
      const convertedName = camelCase(currentAttr.name);
      const convertedValue = currentAttr.value === '' ? true : currentAttr.value; // boolean attributes

      if (filterProps(convertedValue, convertedName, i)) {
        componentProps[convertedName] = convertedValue;
      }
      elementAttributes[currentAttr.name] = currentAttr.value;
    }
  }

  // if there was a loading template given in the dom to display
  // while javascript was loading, remove before trying to mount
  // the React component.
  while (el.firstChild) {
    el.removeChild(el.firstChild);
  }

  const componentConstructor = chooseComponent(elementAttributes);
  if (!componentConstructor) { return; }

  ReactDOM.render(
    React.createElement(componentConstructor, componentProps),
    el,
  );
};

const renderCustomTag = (
  tagName,
  Component,
  filterProps,
  chooseComponent,
) => {
  const els = document.querySelectorAll(tagName);
  for (let i = 0; i < els.length; i++) {
    renderToEl(els[i], Component, filterProps, chooseComponent);
  }
};

export default renderCustomTag;
