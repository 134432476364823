import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { Colors } from 'mineralsoft/components/common/constants';

const loadAnimation = keyframes`
  0% { background: ${Colors.silver}; }
  25% { background: ${Colors.slate}; }
  50% { background: ${Colors.silver}; }
`;

const Animated = styled.div`
  flex-direction: row;
  display: flex;
`;

const Circle = styled.div`
  ${props => `
    width: ${props.size};
    height: ${props.size};
    margin: calc(${props.size} / 3);
  `}
  border-radius: 50%;
  background: ${Colors.softFog};
  animation: ${loadAnimation} 2s ease both infinite;
  animation-delay: ${props => props.animationDelay};
`;

const SubtleIndicator = (props) => {
  const { size } = props;

  return (
    <Animated>
      <Circle size={size} animationDelay="0s" />
      <Circle size={size} animationDelay="0.5s" />
      <Circle size={size} animationDelay="0.75s" />
    </Animated>
  );
};

SubtleIndicator.propTypes = { size: PropTypes.string };
SubtleIndicator.defaultProps = { size: '3px' };

export default SubtleIndicator;
