import React from 'react';
import ReactDOM from 'react-dom';

export default async function renderAsync(selector, load, props = {}) {
  const containers = document.querySelectorAll(selector);
  if (containers.length) {
    const { default: Component } = await load();
    containers.forEach(container => {
      ReactDOM.unmountComponentAtNode(container);
      ReactDOM.render(
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...props} />,
        container,
      );
    });
  }
}
