import styled from 'styled-components';
import { rgba } from 'polished';

import { Colors } from 'mineralsoft/components/common/constants';
import ButtonSizes from './sizes';

const BackgroundLayer = styled.div`
  width: calc(100% + 3px);
  height: calc(100% + 2px);
  border-radius: ${({ size }) => (size === ButtonSizes.small ? 2 : 4)}px;
  position: absolute;
  left: -1px;
  top: -1px;
  z-index: -1;
  transition: opacity 0.15s ease-in-out;
  background: ${rgba(Colors.softFog, 0.5)};
  pointer-events: none;
  opacity: 0;
`;

export default BackgroundLayer;
